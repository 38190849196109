import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import loadable from "@loadable/component";
//               css            //
import Nav from "./screen/components/Navbar";
import { CSSTransition, TransitionGroup } from "react-transition-group";
//               comp          /
//              screen         //
const ContactUs = loadable(() => import("./screen/ContactUs"));
const HomePage = loadable(() => import("./screen/HomePage"));
//import HomePage from "./screen/HomePage";
const Career = loadable(() => import("./screen/Career"));
const Academy = loadable(() => import("./screen/Academy"));
const AboutUs = loadable(() => import("./screen/AboutUs"));
const KeySolutionsPage = loadable(() => import("./screen/KeySolutions"));
const Reviews = loadable(() => import("./screen/Reviews"));
const Blog = loadable(() => import("./screen/Blog"));
const Expertise = loadable(() => import("./screen/Expertise"));
const ExpertisePost = loadable(() =>
  import("./screen/expertise/ExpertisePost")
);
const BlogPost = loadable(() => import("./screen/blog/BlogPostPage"));
const LeaderPost = loadable(() => import("./screen/about/LeaderPost"));
const FormFile = loadable(() => import("./screen/contact/FormFile"));
const Privacy = loadable(() => import("./screen/privacy/Privacy"));
const Admin = loadable(() => import("./admin/Admin"));
// import CustomCursor from "./screenNEW/CustomCursor"

export default function App() {
  const location = useLocation();
  const timeout = { enter: 300, exit: 300 };
  return (
    <>
      <Nav />
      {/* <CustomCursor /> */}
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={timeout}>
          <Routes location={location}>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/home" exact element={<HomePage />} />
            <Route path="/news/:route" element={<BlogPost />} />
            <Route path="/blog/:route" element={<BlogPost />} />
            <Route path="/home" exact element={<HomePage />} />          
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/academy" element={<Academy />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/news" element={<Blog />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/keysolutions" element={<KeySolutionsPage />} />
            <Route path="/expertise" element={<Expertise />} />
            <Route path="/expertise/:route" element={<ExpertisePost />} />
            <Route path="/leader/:route" element={<LeaderPost />} />
            <Route path="/contact/:route" element={<FormFile />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/*" exact element={<HomePage />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Progress.scss";

const Progress = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const { pathname } = useLocation();

  const onScroll = () => {
    const winScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollTop(scrolled);
  };

  useEffect(() => {
    setScrollTop(0);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [pathname]); // <-- Aggiungere il pathname come dipendenza

  return (
    <div className="progress-container">
      <div className="progress-bar-header" style={{ width: `${scrollTop}%` }}></div>
    </div>
  );
};

export default Progress;
